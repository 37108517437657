<template>
  <section>
    <section class="main-section">
      <div class="main-section-image">
        <div class="main-section-title">
          <div class="main-section-title-wrapper">
            <span class="name">Almudena</span>
            <span class="surname">Grandes,</span>
            <span class="accent">con acento</span>
            <span class="description">Recordamos a la escritora madrileña, fallecida en noviembre de 2021, leyendo con acentos del español de ambos lados del Atlántico el inicio de su novela <i class="italic">La madre de Frankenstein</i></span>
          </div>
        </div>
      </div>
    </section>

    <section class="section noline">
      <div class="section-wrapper">
        <p>Os invitamos a escuchar un doble homenaje. A la escritora Almudena Grandes, a la que tanto echamos de menos. Y también al español, la lengua común de millones de personas que están unidas por un idioma que es plural precisamente porque también es universal. Nos entendemos, aunque hablemos distinto. Y parte de la riqueza de esta lengua está en sus acentos, en las distintas maneras de pronunciar cada palabra.</p>
        <p>Este año, para este doble homenaje, hemos convocado a una veintena de escritores, que representan buena parte de los países del español <router-link class="link" to="/acentos/biografias/">–aquí puedes ver quiénes son–</router-link>. Y les hemos pedido que leyeran un mismo texto de Almudena Grandes: el inicio de ‘La madre de Frankenstein’. Para que así podamos comparar, a través de sus voces, los distintos acentos.</p>
        <p>Es un especial que pensamos repetir. Cada año, en el día del libro, elegiremos a un autor al que rendiremos homenaje de esta forma: leyendo su obra. Porque como escribe <router-link class="link" to="/acentos/por-que-almudena-grandes/">en este artículo Elena Cabrera</router-link>, jefa de Cultura de elDiario.es, “solo hay una cosa que devuelve a la vida a una escritora: realizar el sencillo acto de leerla”.</p>
      </div>

      <autopromo></autopromo>

      <section class="section nosection">
        <div class="section-wrapper">
          <p>Este trabajo, que esperamos os guste, es un proyecto conjunto de elDiario.es con la <a class="link" href="https://www.archiletras.com/" target="_blank"> revista Archiletras</a>. Su director, Arsenio Escolar, <router-link class="link" to="/acentos/acentos-del-espanol/">explica en este otro artículo</router-link> los matices fonéticos de las variedades dialectales del español. Según los académicos, son solo ocho grandes dialectos. En nuestro especial hemos recogido muchas más voces, aunque sabemos que esa riqueza de acentos es aún mayor.</p>
          <p>Con este reproductor, puedes escoger el autor, el acento, al que quieres escuchar mientras lees. Para que puedas comparar esa doble belleza: la de la palabra y la voz.</p>
        </div>
      </section>

      <div class="signature">
        <img class="signature-image" src="../assets/img/ignacio.png">
        <div class="signature-info">
          <div class="signature-name">Ignacio Escolar</div>
          <div class="signature-title">Director de elDiario.es</div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Autopromo from "../components/autopromo";
export default {
  name: 'inicio',
  components: {
    Autopromo
  },
}
</script>
