<template>
  <div id="app">
    <div class="logos">
      <div class="logos-wrapper">
        <a href="https://www.eldiario.es/" class="logo-diario"><img src="./assets/icons/eldiario.svg" alt="El Diario"></a>
        <span class="logo-separator"></span>
        <a href="https://www.archiletras.com/" class="logo-archiletras"><img src="./assets/icons/archiletras.svg" alt="Archiletras"></a>
      </div>
    </div>

    <Header></Header>

    <main>
      <router-view></router-view>
    </main>

    <audio-control v-if="isHomePage"/>
  </div>
</template>

<script>
import Header from "./components/header.vue";
import AudioControl from "./components/audio-control.vue";

export default {
  name: 'App',
  data() {
    return {
      header: {
        sticky: false,
        stickable: false,
        showElements: true,
        delta: 5,
        lastScrollPosition: 0,
        openMenu: false,
        showAudio: false,
        showColor: false,
      },
    }
  },
  components: {
    Header,
    AudioControl,
  },
  created() {
    window.addEventListener('scroll', this.handleScroll, {passive: true});
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, {passive: true});
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'inicio'
    },
  },
  methods: {
    handleScroll() {
      if (document.getElementsByClassName('section-audio')[0]) {
        const accentContainer = document.getElementsByClassName('section-audio')[0].offsetTop - window.innerHeight + 180;
        this.header.showAudio = window.pageYOffset > 1;
        this.header.showColor = window.pageYOffset > accentContainer;
      }

      const currentScrollPosition = (window.pageYOffset || document.documentElement.scrollTop);
      if (Math.abs(this.header.lastScrollPosition - currentScrollPosition) <= this.header.delta) {
        this.header.lastScrollPosition = currentScrollPosition;
        return;
      }

      if (currentScrollPosition < 300) {
        // Normal header no sticky
        this.header.sticky = false;
        this.header.stickable = false;
        this.header.lastScrollPosition = currentScrollPosition;
        return;
      }
      if (currentScrollPosition > this.header.lastScrollPosition) {
        // Scrolling down
        this.header.showElements = this.header.openMenu;
        this.header.stickable = true;
        this.header.sticky = this.header.openMenu;
      } else {
        // Scrolling Up
        this.header.showElements = true;
        this.header.stickable = false;
        this.header.sticky = true;
      }

      this.header.lastScrollPosition = currentScrollPosition;
    },
    scrollToElement(elementId) {
      this.showMenu = false;
      const el = document.getElementById(elementId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }
}
</script>
<style src="./assets/scss/main.scss" lang="scss"></style>
