<template>
  <section class="section-promo">
    <div class="section-promo-wrapper">
      <div class="section-promo-info">
        <span class="title">Una propuesta muy especial para el Día del Libro</span>
        <span class="subtitle">
              Hazte socia/o de elDiario.es y llévate los cinco libros de 'Episodios de una guerra interminable' de Almudena Grandes
        </span>
      </div>
      <div class="section-promo-image">
        <img class="books" src="../assets/img/books_.png">
        <img class="promo" src="../assets/img/promo.png">
      </div>
      <div class="section-promo-actions">
        <a href="https://l.eldiario.es/dia-del-libro?itm_n=acentos&itm_c=especiales" class="promo-button" target="_blank">¡Lo quiero!</a>
        <div class="promo-logo">
          <img src="../assets/icons/eldiario.svg" alt="El Diario">
          <span>Contigo sumamos más</span>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'autopromo',
}
</script>
