export default {
  props: {

  },
  data() {
    return {
      showMenu: false,
      url: 'https://acentos.eldiario.es/',
      shareText: 'Almudena Grandes, con acento'
    }
  },
  computed: {
    setHeadetTitle() {
      return "Acentos del español";
    },
    shareTwitter(){
      return "https://twitter.com/share?url=https://www.eldiario.es/acentos/&text=" + encodeURIComponent(this.shareText);
    },
    shareFacebook(){
      return "https://www.facebook.com/sharer/sharer.php?u=https://www.eldiario.es/acentos/";
    },
    shareTelegram(){
      return "https://t.me/share/url?url=https://www.eldiario.es/acentos/&text=" + encodeURIComponent(this.shareText);
    },
    shareWhatsapp(){
      return 'https://api.whatsapp.com/send?text=' + encodeURIComponent(this.shareText) + ' https://www.eldiario.es/acentos/';
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      this.showMenu = false;
    },
    scrollToElement(elementId) {
      this.showMenu = false;
      const el = document.getElementById(elementId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }
}
