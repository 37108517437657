import RabbitLyrics from 'rabbit-lyrics'
import jpgAriel from '../assets/readers/audio_Ariel_Florencia_Richards.jpg';
import mp3Ariel from '../assets/readers/audio_Ariel_Florencia_Richards.mp3';
import srtAriel from 'raw-loader!../assets/readers/audio_Ariel_Florencia_Richards.mp3.srt';
import jpgCarlos from '../assets/readers/audio_Carlos_Manuel_Alvarez.jpeg';
import mp3Carlos from '../assets/readers/audio_Carlos_Manuel_Alvarez.mp3';
import srtCarlos from 'raw-loader!../assets/readers/audio_Carlos_Manuel_Alvarez.mp3.srt';
import jpgCristian from '../assets/readers/audio_Cristian_Alarcon.jpg';
import mp3Cristian from '../assets/readers/audio_Cristian_Alarcon.mp3';
import srtCristian from 'raw-loader!../assets/readers/audio_Cristian_Alarcon.mp3.srt';
import jpgFernanda from '../assets/readers/audio_Fernanda_Trias.jpg';
import mp3Fernanda from '../assets/readers/audio_Fernanda_Trias.mp3';
import srtFernanda from 'raw-loader!../assets/readers/audio_Fernanda_Trias.mp3.srt';
import jpgEduardo from '../assets/readers/audio_Eduardo_Mendicutti.jpg';
import mp3Eduardo from '../assets/readers/audio_Eduardo_Mendicutti.mp3';
import srtEduardo from 'raw-loader!../assets/readers/audio_Eduardo_Mendicutti.mp3.srt';
import jpgGabriela from '../assets/readers/audio_Gabriela_Wiener.jpg';
import mp3Gabriela from '../assets/readers/audio_Gabriela_Wiener.mp3';
import srtGabriela from 'raw-loader!../assets/readers/audio_Gabriela_Wiener.mp3.srt';
import jpgGioconda from '../assets/readers/audio_Gioconda_Belli.jpg';
import mp3Gioconda from '../assets/readers/audio_Gioconda_Belli.mp3';
import srtGioconda from 'raw-loader!../assets/readers/audio_Gioconda_Belli.mp3.srt';
import jpgJuanC from '../assets/readers/audio_Juan_Cruz.jpg';
import mp3JuanC  from '../assets/readers/audio_Juan_Cruz.mp3';
import srtJuanC  from 'raw-loader!../assets/readers/audio_Juan_Cruz.mp3.srt';
import jpgJuanT from '../assets/readers/audio_Juan_Tallon.jpg';
import mp3JuanT from '../assets/readers/audio_Juan_Tallon.mp3';
import srtJuanT from 'raw-loader!../assets/readers/audio_Juan_Tallon.mp3.srt';
import jpgJuanV from '../assets/readers/audio_Juan_Villoro.jpg';
import mp3JuanV from '../assets/readers/audio_Juan_Villoro.mp3';
import srtJuanV from 'raw-loader!../assets/readers/audio_Juan_Villoro.mp3.srt';
import jpgLuis from '../assets/readers/audio_Luis_Garcia_Montero.jpg';
import mp3Luis from '../assets/readers/audio_Luis_Garcia_Montero.mp3';
import srtLuis from 'raw-loader!../assets/readers/audio_Luis_Garcia_Montero.mp3.srt';
import jpgLuna from '../assets/readers/audio_Luna_Miguel.jpg';
import mp3Luna from '../assets/readers/audio_Luna_Miguel.mp3';
import srtLuna from 'raw-loader!../assets/readers/audio_Luna_Miguel.mp3.srt';
import jpgMariana from '../assets/readers/audio_Mariana_Enriquez.jpg';
import mp3Mariana from '../assets/readers/audio_Mariana_Enriquez.mp3';
import srtMariana from 'raw-loader!../assets/readers/audio_Mariana_Enriquez.mp3.srt';
import jpgMiren from '../assets/readers/audio_Miren_Agur_Meabe.jpg';
import mp3Miren from '../assets/readers/audio_Miren_Agurtzane_Meabe.mp3';
import srtMiren from 'raw-loader!../assets/readers/audio_Miren_Agurtzane_Meabe.srt';
import jpgMonica from '../assets/readers/audio_Monica_Bustos.jpg';
import mp3Monica from '../assets/readers/audio_Monica_Bustos.mp3';
import srtMonica from 'raw-loader!../assets/readers/audio_Monica_Bustos.mp3.srt';
import jpgPiedad from '../assets/readers/audio_Piedad_Bonnett.jpg';
import mp3Piedad from '../assets/readers/audio_Piedad_Bonnett.mp3';
import srtPiedad from 'raw-loader!../assets/readers/audio_Piedad_Bonnett.mp3.srt';
import jpgSandra from '../assets/readers/audio_Sandra_Lorenzano.jpg';
import mp3Sandra from '../assets/readers/audio_Sandra_Lorenzano.mp3';
import srtSandra from 'raw-loader!../assets/readers/audio_Sandra_Lorenzano.mp3.srt';
import jpgSergio from '../assets/readers/audio_Sergio_Ramirez.jpg';
import mp3Sergio from '../assets/readers/audio_Sergio_Ramirez.mp3';
import srtSergio from 'raw-loader!../assets/readers/audio_Sergio_Ramirez.mp3.srt';
import jpgTrifonia from '../assets/readers/audio_Trifonia_Melibea_Obono.jpg';
import mp3Trifonia from '../assets/readers/audio_Trifonia_Melibea_Obono.mp3';
import srtTrifonia from 'raw-loader!../assets/readers/audio_Trifonia_Melibea_Obono.mp3.srt';
import Argentina from '../assets/flags/Argentina.svg';
import Chile from '../assets/flags/Chile.svg';
import Colombia from '../assets/flags/Colombia.svg';
import Cuba from '../assets/flags/Cuba.svg';
import Espana from '../assets/flags/Espana.svg';
import GuineaEcuatorial from '../assets/flags/GuineaEcuatorial.svg';
import Mexico from '../assets/flags/Mexico.svg';
import Nicaragua from '../assets/flags/Nicaragua.svg';
import Paraguay from '../assets/flags/Paraguay.svg';
import Peru from '../assets/flags/Peru.svg';
import Uruguay from '../assets/flags/Uruguay.svg';

export default {
  name: "audio-control",
  data() {
    return {
      currentTime: 0,
      totalTime: 0,
      currentLyric: srtLuis,
      currentAudio: mp3Luis,
      Rabbit: null,
      currentColor: 0,
      left: 0,
      isPlaying: false,
      colors: [
        '#17CFF8',//azul
        '#E19B69',//naranha
        '#80B4B8',//zul
        '#79A58A',//verde
        '#86969A',//gris
        '#BBB96C',//ocre
        '#C8D62E',//amarillo
        '#BBB96C', //ocre
        '#86969A', //gris
        '#79A58A', //verde
        '#80B4B8', //zul
        '#E19B69', //naranja
      ],
      bgColors: [
        '#d1f5fe', //azul
        '#f9ebe1', //naranha
        '#e6f0f1', //zul
        '#e4ede8', //verde
        '#e7eaeb', //gris
        '#f1f1e2', //ocre
        '#f4f7d5', //amarillo
        '#f1f1e2',  //ocre
        '#e7eaeb',  //gris
        '#e4ede8',  //verde
        '#e6f0f1',  //zul
        '#f9ebe1',  //naranja
      ],
      currentAuthor: 9,
      authors: [
        {
          'country': 'Chile',
          'name': 'Ariel Florencia Richards',
          'title': 'Santiago de Chile',
          'photo': jpgAriel,
          'audio': mp3Ariel,
          'lyric': srtAriel,
          'css': 'srtAriel rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Chile,
        },
        {
          'country': 'Cuba',
          'name': 'Carlos Manuel Álvarez',
          'title': 'Matanzas',
          'photo': jpgCarlos,
          'audio': mp3Carlos,
          'lyric': srtCarlos,
          'css': 'srtCarlos rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Cuba,
        },
        {
          'country': 'Nicaragua',
          'name': 'Gioconda Belli',
          'title': 'Managua',
          'photo': jpgGioconda,
          'audio': mp3Gioconda,
          'lyric': srtGioconda,
          'css': 'srtGioconda rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Nicaragua,
        },
        {
          'country': 'España',
          'name': 'Juan Tallon',
          'title': 'Vilardevós, Ourense',
          'photo': jpgJuanT,
          'audio': mp3JuanT,
          'lyric': srtJuanT,
          'css': 'srtJuanT rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Espana,
        },
        {
          'country': 'Peru',
          'name': 'Gabriela Wiener',
          'title': 'Lima',
          'photo': jpgGabriela,
          'audio': mp3Gabriela,
          'lyric': srtGabriela,
          'css': 'srtGabriela rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Peru,
        },
        {
          'country': 'México',
          'name': 'Juan Villoro',
          'title': 'Ciudad de México',
          'photo': jpgJuanV,
          'audio': mp3JuanV,
          'lyric': srtJuanV,
          'css': 'srtJuanV rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Mexico,
        },
        {
          'country': 'España',
          'name': 'Luna Miguel',
          'title': 'Alcalá de Henares, Madrid',
          'photo': jpgLuna,
          'audio': mp3Luna,
          'lyric': srtLuna,
          'css': 'srtLuna rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Espana,
        },
        {
          'country': 'Nicaragua',
          'name': 'Sergio Ramirez',
          'title': 'Masatepe',
          'photo': jpgSergio,
          'audio': mp3Sergio,
          'lyric': srtSergio,
          'css': 'srtSergio rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Nicaragua,
        },
        {
          'country': 'Argentina',
          'name': 'Mariana Enriquez',
          'title': 'Buenos Aires',
          'photo': jpgMariana,
          'audio': mp3Mariana,
          'lyric': srtMariana,
          'css': 'srtMariana rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Argentina,
        },
        {
          'country': 'España',
          'name': 'Luis Garcia Montero',
          'title': 'Granada',
          'photo': jpgLuis,
          'audio': mp3Luis,
          'lyric': srtLuis,
          'css': 'srtLuis rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Espana,
        },
        {
          'country': 'Colombia',
          'name': 'Piedad Bonett',
          'title': 'Amalfi',
          'photo': jpgPiedad,
          'audio': mp3Piedad,
          'lyric': srtPiedad,
          'css': 'srtPiedad rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Colombia,
        },
        {
          'country': 'España',
          'name': 'Eduardo Mendicutti',
          'title': 'Sanlúcar de Barrameda, Cádiz',
          'photo': jpgEduardo,
          'audio': mp3Eduardo,
          'lyric': srtEduardo,
          'css': 'srtEduardo rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Espana,
        },
        {
          'country': 'Paraguay',
          'name': 'Monica Bustos',
          'title': 'Asunción',
          'photo': jpgMonica,
          'audio': mp3Monica,
          'lyric': srtMonica,
          'css': 'srtMonica rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Paraguay,
        },
        {
          'country': 'Chile',
          'name': 'Cristian Alarcon',
          'title': 'La Unión',
          'photo': jpgCristian,
          'audio': mp3Cristian,
          'lyric': srtCristian,
          'css': 'srtCristian rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Chile,
        },
        {
          'country': 'España',
          'name': 'Miren Agur Meabe',
          'title': 'Lekeitio, Bizkaia',
          'photo': jpgMiren,
          'audio': mp3Miren,
          'lyric': srtMiren,
          'css': 'srtMiren rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Espana,
        },
        {
          'country': 'Uruguay',
          'name': 'Fernanda Trias',
          'title': 'Montevideo',
          'photo': jpgFernanda,
          'audio': mp3Fernanda,
          'lyric': srtFernanda,
          'css': 'srtFernanda rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Uruguay,
        },
        {
          'country': 'Argentina',
          'name': 'Sandra Lorenzano',
          'title': 'Buenos Aires',
          'photo': jpgSandra,
          'audio': mp3Sandra,
          'lyric': srtSandra,
          'css': 'srtSandra rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Argentina,
        },
        {
          'country': 'España',
          'name': 'Juan Cruz',
          'title': 'Puerto de la Cruz, Tenerife',
          'photo': jpgJuanC,
          'audio': mp3JuanC,
          'lyric': srtJuanC,
          'css': 'srtJuanC rabbit-lyrics--full rabbit-lyrics--center',
          'flag': Espana,
        },
        {
          'country': 'Guinea Ecuatorial',
          'name': 'Trifonia Melibea Obono',
          'title': 'Afaetom, Evineyong',
          'photo': jpgTrifonia,
          'audio': mp3Trifonia,
          'lyric': srtTrifonia,
          'css': 'srtTrifonia rabbit-lyrics--full rabbit-lyrics--center',
          'flag': GuineaEcuatorial,
        },
      ]
    }
  },
  mounted() {
    this.Rabbit = new RabbitLyrics(
      document.getElementById('acentos'),
      document.getElementById('audio'),
      {
        lyrics: this.currentLyric,
        viewMode: "full"
      }
    )
    if (window.innerWidth > 700) {
      this.left = (660 / 2) - (9 * 58) - 46.5;
    } else {
      this.left = (window.innerWidth / 2) - (9 * 58) - 46.5;
    }
  },
  computed: {
    cssVars() {
      if (this.$parent.header.showColor) {
        return {
          '--color': this.colors[this.currentColor],
          '--bg-color':  this.bgColors[this.currentColor]
        }
      }
      return {
        '--color': '#fdfdfd',
        '--bg-color': '#fdfdfd'
      }
    },
    facesMove() {
      return `left: ${this.left}px;`
    },
    setColor() {
      return `background-color: ${this.colors[this.currentColor]};`
    },
    setPlayIcon() {
      if (this.isPlaying) {
        return 'fas fa-pause';
      }
      return 'fas fa-play';
    },
    setCurrentTimeline() {
      const total = this.currentTime / this.totalTime * 100;
      return `width: ${total}%`
    },
    showRightArrow() {
      return  this.currentAuthor < 18;
    },
    showLeftArrow() {
      return  this.currentAuthor > 0;
    }
  },
  methods: {
    formatTime(sec) {
      if (sec) {
        let date = new Date(0);
        date.setSeconds(sec);
        return date.toISOString().substr(14, 5);
      }
      return '';
    },
    togglePlay() {
      if (!this.$parent.header.showColor && !this.isPlaying) {
        this.scrollToElement('accents');
      }

      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        this.$refs.accents.play();
        this.totalTime = this.$refs.accents.duration;
        console.log("SEND EVENT: Play");
      } else {
        this.$refs.accents.pause();
        console.log("SEND EVENT: Pause");
      }

    },
    onTimeUpdateListener() {
      this.totalTime = this.$refs.accents.duration;
      this.currentTime = this.$refs.accents.currentTime
    },
    newRabbit() {

    },
    goToLeft() {
      this.changeAudio(this.currentAuthor - 1)
    },
    goToRight() {
      this.changeAudio(this.currentAuthor + 1)
    },
    changeAudio(id) {
      if (!this.$parent.header.showColor && !this.isPlaying) {
        this.scrollToElement('accents');
      }
      console.log("SEND EVENT: Change Author");
      this.currentTime = this.$refs.accents.currentTime;
      this.totalTime = this.$refs.accents.duration;
      this.$refs.accents.load();
      this.currentAudio = this.authors[id].audio;
      this.currentLyric = this.authors[id].lyric;
      this.currentAuthor = id;
      this.Rabbit.setLyrics(this.currentLyric);
      this.$refs.accents.currentTime = 0;
      this.totalTime = this.$refs.accents.duration;
      this.isPlaying = true;
      this.$parent.showAudio = true;
      this.currentColor += 1;
      this.$refs.accents.load();
      this.$refs.accents.play();
      if (this.currentColor > this.colors.length -1) {
        this.currentColor = 0;
      }
      this.centerFace();
    },
    changeAudioTime(event) {
      const click = (this.totalTime * event.offsetX) / 232;
      this.$refs.accents.currentTime = click;
      this.currentTime = click;
    },
    centerFace() {
      this.left = (this.$refs.audioControlWrapper.offsetWidth / 2) - (this.currentAuthor * 58) - 46.5;
    },
    moveFaces(e) {
      if (e.type === 'swipeleft') {
        this.left = this.left - e.distance;
      }
      else if (e.type === 'swiperight') {
        this.left = this.left + e.distance;
      }
      if (this.left > 285) {
        this.left = 285;
      }
      if (this.left < -640) {
        this.left = -640;
      }
    },
    scrollToElement(elementId) {
      this.showMenu = false;
      const el = document.getElementById(elementId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }
}
