<template>
  <section class="section">
    <div id="section2" style="position: absolute; top: -57px; left: 0"></div>
    <div class="section-wrapper">
      <div class="section-header">
        <span class="section-header-number"></span>
        <span class="section-header-title">Biografias</span>
        <span class="section-header-subtitle">
          <div class="name"></div>
          <div class="info"></div>
        </span>
      </div>
      <figure class="section-image">
        <img src="../assets/img/biografias.png">
      </figure>
      <p>Escritores y escritoras del español son las voces reunidas al calor del mismo texto de Almudena Grandes, para poner en su boca las palabras de ella y escucharla con mil acentos diferentes que nos recuerdan que el español es un idioma interminable.</p>

      <span class="reader-country">Chile</span>
      <span class="reader-text">
            <span class="reader-name">Ariel Richards (Santiago, 1981).</span>
            Escritora y crítica de artes visuales, publicó su poemario Trasatlántico en 2015 y su primera novela Las olas son las mismas, un año después. En su libro, dos historias se entrelazan en un texto con aliento de bitácora de viaje o diario íntimo y que reflexiona sobre el género, la literatura, la identidad y lo que se calla. Sus primeras obras fueron publicadas bajo el nombre Juan José Richards y reeditadas como Ariel Florencia Richards a partir de su transición de género, a los 37 años. Ha publicado fanzines, ediciones independientes y cuadernos en ferias de arte impreso. Actualmente escribe sobre arte contemporáneo y cursa un doctorado en Artes donde investiga la representación del género a través del cuerpo en las performances.
          </span>

      <span class="reader-country">Cuba</span>
      <span class="reader-text">
            <span class="reader-name">Carlos Manuel Álvarez (Matanzas, 1989).</span>
            Periodista, o más bien cronista, de La Habana de su tiempo, Carlos Manuel Álvarez ha sido seleccionado por la revista Granta como uno de los 25 mejores escritores jóvenes en español en 2021. Su periodismo abundante, detallista, colorido, hondo, complejo, descarnado se puede leer, escogido, en La tribu: Retratos de Cuba pero son muchos los medios de prestigio en los que publica, además de editar las revistas The Clinic (Chile) y El Estornudo (Cuba). En 2018 se adentró en la narrativa con Los caídos, donde el microcosmos de una familia enfrentada se convierte en metáfora de la sociedad, y en 2021 con Falsa guerra, sobre ese territorio inestable habitado por los que se han ido, los que se quieren ir y los que se quedan.
          </span>

      <span class="reader-country">Chile</span>
      <span class="reader-text">
            <span class="reader-name">Cristian Alarcón (La Unión, 1970).</span>
            Cuando tenía cuatro años, su familia emigró a la Patagonia escapando del régimen de Pinochet. Escritor, periodista y fundador de medios en Argentina, comenzó su carrera en la crónica y en la investigación. En los 90 llegó a ahondar en el mundo de las bandas criminales y del narcotráfico, pero desde entonces ha experimentado con la crónica latinoamericana. Para ello fundó en 2012 la Revista Anfibia y la web Cosecha Roja, donde recoge noticias policiales con perspectiva de género. Ha publicado cuatro libros y con el último, El tercer paraíso, se ha alzado con el 25 Premio de Novela de Alfaguara. También es profesor de periodismo en la Universidad Nacional de La Plata.
          </span>

      <span class="reader-country">España</span>
      <span class="reader-text">
            <span class="reader-name">Eduardo Mendicutti (Sanlúcar de Barrameda, Cádiz. 1948).</span>
            Escritor y periodista, comenzó su escritura en las distancias cortas de la narrativa, en los años 70, cuando se trasladó a Madrid para cursar estudios de periodismo. Sus primeras novelas no pudieron sortear la censura franquista y no se publicaron hasta que llegó la democracia. En ellas, y en general a lo largo de su obra, se pone en relevancia la disidencia sexual de sus personajes homosexuales, por lo que ha sido aclamado como referente de liberación para varias generaciones. Cronista de cierta marginalidad, su escritura toma forma con las herramientas del humor y la ironía.
          </span>

      <span class="reader-country">Uruguay</span>
      <span class="reader-text">
            <span class="reader-name">Fernanda Trías (Montevideo, 1976).</span>
            Ganadora del premio Sor Juana Inés de la Cruz por su cuarta novela Mugre rosa (2020), una distopía que es en cierta manera premonitoria de la pandemia que le esperaba al mundo muy poco después de que terminara de escribirla. Trías, quien además también es traductora y profesora de escritura, teje su literatura tanto en corto —tiene dos libros de relatos— como en largo con un lenguaje de elegancia sobria y matices. Presa de los abismos, del pasado, de los miedos, sus narraciones intentan buscar una salida que no siempre encuentran. La uruguaya ha vivido, en los últimos años, de manera itinerante en Berlín, Buenos Aires, Nueva York, Valparaíso, Bogotá y Madrid.
          </span>

      <span class="reader-country">Peru</span>
      <span class="reader-text">
            <span class="reader-name">Gabriela Wiener (Lima, 1975).</span>
            La escritora peruana surgió del periodismo como una mala hierba: indomable, extraña, irreductible. Se dijo de ella que hacía periodismo gonzo porque se involucraba en sus crónicas, viviéndolas de manera protagónica, contándolo después con honestidad visceral. Pero al final resultó que gonzo no era solo el periodismo sino su vida entera: saltando sin red, arrojándose al centro de la historia sin saber dónde va a terminar. Contó su embarazo en Nueve lunas, su viaje antropólogo por el placer en Sexografías, su desvergüenza en Llamada perdida, su descolonialismo en Huaco retrato y su destrucción y reconstrucción del formato familiar en la obra teatral Qué locura enamorarme yo de ti. En sus poemas Ejercicios para el endurecimiento del espíritu, Gabriela Wiener se abre en canal, como en cada palabra que elige.
          </span>

      <span class="reader-country">Nicaragua</span>
      <span class="reader-text">
            <span class="reader-name">Gioconda Belli (Managua, 1948).</span>
            Hay una Gioconda Belli novelista, una Gioconda Belli poeta y una Gioconda ciudadana, según ella misma dice. Sus primeros poemas, publicados en los años 70, rebosaban corporeidad y carnalidad. La militante acompaña a la poeta y la autora se integra en el Frente Sandinista de Liberación Nacional (FSLN) nicaragüense. Perseguida por las fuerzas de seguridad somocistas, se vio abocada al exilio, del que volvió, con el triunfo de la revolución sandinista para desempeñar diversos cargos diplomáticos en el Gobierno. La Gioconda novelista llega en 1988 con su primera novela, La mujer habitada, la primera de muchas y un gran éxito internacional.
          </span>

      <span class="reader-country">España</span>
      <span class="reader-text">
            <span class="reader-name">Juan Cruz (Puerto de la Cruz, Tenerife, 1948).</span>
            Periodista, novelista, ensayista, biógrafo, editor y también poeta. Juan Cruz fue uno de los fundadores del diario El País en 1976 hasta que en 2022 se convierte en adjunto a la presidencia del grupo Prensa Ibérica. Apasionado entrevistador, cronista incesante de su tiempo, cautivado por el oficio del periodismo desde los 14 años y minucioso observador de la naturaleza humana, como se detecta en su profusa obra narrativa.
          </span>

      <span class="reader-country"></span>
      <span class="reader-text">
            <span class="reader-name">Juan Tallón (Vilardevós, Ourense, 1975).</span>
            Licenciado en filosofía y periodista de profesión, Tallón escribe novelas tanto en gallego como en castellano. Le ha dado a la no ficción, como en su revisión de lecturas Libros peligrosos o las reflexiones afiladas de Mientras haya bares, producto del columnismo, que practica con maestría. A su obra le gusta revolver la actualidad con la ficción y la irrealidad con lo irremediable. Sus dos últimas novelas son Rewind, sobre la imposibilidad de volver atrás y Obra maestra, acerca de la desaparición de la escultura de Richard Serra en el Reina Sofía.
          </span>

      <span class="reader-country">México</span>
      <span class="reader-text">
            <span class="reader-name">Juan Villoro (Ciudad de México, 1956).</span>
            Hijo de padre español y madre yucateca, Villoro es un cronista agudo, irónico e inteligente, siempre dispuesto al asombro y una de las voces más reconocidas de la  literatura mexicana actual. Ha escrito relatos, cuentos para niños y jóvenes, novelas, obras de teatro, crónicas de viajes y de fútbol e incluso novela gráfica. Recibió el Premio Herralde con su novela El testigo, así como otros premios, alguno de periodismo, como el Premio Iberoamericano José Donoso 2012 por el conjunto de su obra. De sus relatos escribió Roberto Bolaño que tienen ese raro poder para permanecer en el borde del abismo. En su última novela, La tierra de la gran promesa, traza una gran metáfora del México contemporáneo.
          </span>

      <span class="reader-country">España</span>
      <span class="reader-text">
            <span class="reader-name">Luis García Montero (Granada, España, 1958).</span>
            Poeta, novelista ocasional, ensayista accidental y venerado catedrático de Literatura Española en la Universidad de Granada, actualmente dirige el Instituto Cervantes. García Montero es una de las voces de mayor influencia en la poética española y en la intelectualidad cultural. Profundamente lorquiano, honesto, voz de la clase trabajadora y revelador de profundos secretos sobre el amor y la muerte. Su esposa y compañera de literatura, de lucha y de vida ha sido la fallecida escritora Almudena Grandes, a quien se rinde tributo en esta primera edición de Los acentos del español.
          </span>

      <span class="reader-country"></span>
      <span class="reader-text">
            <span class="reader-name">Luna Miguel (Alcalá de Henares, Madrid, 1990).</span>
            Principalmente poeta, y publicando desde los 18 años, Luna Miguel es también periodista y editora, siempre atenta al descubrimiento generoso de nuevas voces poéticas y narrativas. Pero también es ensayista de conspiradora fuerza feminista y novelista. Disecciona con el mismo exquisito bisturí las lecturas y el deseo, que a menudo se entremezclan en su obra. En El coloquio de las perras, recordó a todas las autoras hispanohablantes que fueron ninguneadas.
          </span>

      <span class="reader-country">Argentina</span>
      <span class="reader-text">
            <span class="reader-name">Mariana Enríquez (Buenos Aires, 1973).</span>
            Creadora de universos oscuros y fascinantes, Enríquez es, además de novelista, docente y periodista, ejerciendo como subeditora del suplemento Radar del diario Página/12. Su primera novela fue la impactante Bajar es lo peor, en el que retrata el Buenos Aires nocturno, sórdido y punk de los años noventa y que escribió con 19 años. Su libro Nuestra parte de noche mereció el Premio Herralde de Novela y el Premio de la Crítica en 2019. Ha escrito también relatos y La hermana menor, acerca de la escritora Silvina Ocampo. Enríquez transforma y retuerce los géneros, se pasea con maestría entre ellos, del terror al humor, de la novela negra al realismo sucio, siempre rozando el dolor y la belleza.
          </span>

      <span class="reader-country">España</span>
      <span class="reader-text">
            <span class="reader-name">Miren Agur Meabe (Lekeitio, Bizkaia, 1962).</span>
            Poeta, novelista y traductora en euskera, además de editora, Meabe recibió en 2021 el Premio Nacional de Poesía, consiguiendo que se otorgara por primera vez a una obra escrita en euskera, por Nola gorde errautsa kolkoan, traducida al castellano por ella misma bajo el título de Cómo guardar ceniza en el pecho. Otros poemarios suyos también han merecido premios, como Azalaren kodea (El código de la piel) o Bitsa eskuetan (Espuma en las manos). También sabe escribir para los jóvenes, consiguiendo hasta en tres ocasiones el Premio Euskadi de Literatura Juvenil en tres ocasiones por las obras Itsaslabarreko etxea (La casa del acantilado), Urtebete itsasargian (Un año en el faro) y Errepidea (La carretera).
          </span>

      <span class="reader-country">Paraguay</span>
      <span class="reader-text">
            <span class="reader-name">Mónica Bustos (Asunción, 1984).</span>
            A los 19 años publicó su primera novela, León muerto, y al año siguiente, su primer libro de relatos. Lectora insaciable desde muy niña, obsesionada con Edgar Alan Poe y con Dostoyevski tan solo con 12 años. Consiguió el primer Premio Augusto Roa Bastos de Novela con Chico Bizarro y las moscas, una obra con mayor influencia de la literatura latinoamericana y la cultura estadounidense que sus trabajos anteriores. Con la publicación de Novela B en 2013 coincide no solo darse a conocer fuera de su país sino despertar interés por la desconocida literatura paraguaya. En ese libro asoma, una vez más, la querencia de la autora por los géneros oscuros, los trastornos psicológicos, los personajes de los márgenes y la llamada serie b.
          </span>

      <span class="reader-country">Colombia</span>
      <span class="reader-text">
            <span class="reader-name">Piedad Bonnett (Amalfi, 1951).</span>
            Poeta, autora dramática y novelista, la colombiana ha publicado una gran obra poética —reunida en un volumen único en 2016— y cuyo talento mereció en 1994, a sus 43 años, el Premio Nacional de Poesía de su país. A lo largo de su vida ha ido recibiendo otros prestigiosos premios literarios mientras jalonaba las librerías, en los últimos veinte años, de novelas íntimas y potentes, como la que dedicó a la muerte de su hijo en 2013: Lo que no tiene nombre. Sus voces poética y narrativa tienen mucho en común, y a menudo se nutren del universo interior, de la familia, de las relaciones humanas. En Qué hacer con estos pedazos, publicada en 2022, la autora se adentra en la insatisfacción de la vida en pareja.
          </span>

      <span class="reader-country">Argentina</span>
      <span class="reader-text">
            <span class="reader-name">Sandra Lorenzano (Buenos Aires, 1960).</span>
            La narradora, poeta, ensayista y académica argentina-mexicana es también directora de Cultura y Comunicación de la Coordinación para la Igualdad de Género de la Universidad Nacional Autónoma de México. Aunque se crió en Argentina, se exilió a los 16 años a México, huyendo de la dictadura, un tema al que vuelve con recurrencia en su obra narrativa, salvo en Fuga en mí menor, donde aborda el tema de la música y la memoria. Ha escrito dos poemarios, un ensayo de “aproximaciones” a sor Juana Inés de la Cruz y, además, es especialista en arte y literatura latinoamericanos.
          </span>

      <span class="reader-country">Nicaragua</span>
      <span class="reader-text">
            <span class="reader-name">Sergio Ramírez (Masatepe, 1942).</span>
            Escritor y político nicaraguense, Ramírez participó de la revolución sandinista que derrocó a Somoza en 1979, como uno de sus líderes más destacados. Fue vicepresidente de Daniel Ortega hasta las elecciones de 1990. Posteriormente, sus caminos se separaron hasta tal punto de que, con el actual Gobierno de Ortega, el escritor se ha visto obligado a exiliarse en España debido a la persecución a la que se ha visto sometido. De las revueltas surgidas por la represión policial de Ortega en 2018 surgió su última novela Tongolele no sabía bailar.
          </span>

      <span class="reader-country">Guinea Ecuatorial</span>
      <span class="reader-text">
            <span class="reader-name">Trifonia Melibea Obono (Afaetom, Evineyong, 1982).</span>
            Periodista, politóloga, docente e investigadora sobre temas de mujer y género en África, la autora vive entre Guinea, donde es profesora en la facultad de Letras y Ciencias Sociales de la Universidad Nacional de Guinea Ecuatorial, en Malabo, y España, donde forma parte del Centro de Estudios Afro-Hispánicos (CEAH) de la Universidad Nacional de Educación a Distancia. Su relato La negra fue incluido en la antología Voces femeninas de Guinea Ecuatorial. Y su primera novela llegó en 2016, Herencia de bindendee fue su primera novela, donde aborda la cultura fang, una etnia que habita entre Guinea Ecuatorial, Gabón y Camerún, y que hablan tanto fang como castellano. Al año siguiente publicó La bastarda, dando voz a un tabú como el de las lesbianas en su país.
          </span>
    </div>

    <autopromo></autopromo>
  </section>
</template>

<script>
import Autopromo from "../components/autopromo";
export default {
  name: 'biografias',
  components: {
    Autopromo
  },
}
</script>
