<template>
  <section class="section">
    <div id="section1" style="position: absolute; top: -57px; left: 0"></div>
    <div class="section-wrapper">
      <div class="section-header">
        <span class="section-header-number"></span>
        <span class="section-header-title">Almudena Grandes, el eco de la memoria</span>
        <span class="section-header-subtitle">
          <div class="name">Por Elena Cabrera</div>
          <div class="info">Jefa de Cultura de elDiario.es</div>
        </span>
      </div>

      <figure class="section-image">
        <img src="../assets/img/almudena.png">
      </figure>

      <p>Si los nombres de las calles, de las bibliotecas o de las estaciones de tren. Solo hay una cosa que devuelve a la vida a una escritora: realizar el sencillo acto de leerla. Y al hacerlo en alto, nuestra voz se funde con la de ella. </p>
      <p>Rendir homenaje a la literatura de la desaparecida Almudena Grandes es un acto de amor no solo a su obra, sino al poder de la palabra y su capacidad de resonar, vibrar en el aire, ser presencia. </p>
      <p>A la Grandes se la ha leído mucho y bien, desde que consiguió, con apenas 29 años, el premio La Sonrisa Vertical con <i>Las edades de Lulú</i>, que para muchas lectoras fue un manual de iniciación, una liberación carnal y una advertencia de poder a la par que de vulnerabilidad. Para Almudena, fue una catapulta a la fama. </p>
      <p>A partir de ahí, la escritora abrió las compuertas de su narrativa con una energía desbordante a lo largo de una docena de novelas. De la intimidad pasó a una perspectiva más política con <i>Los aires difíciles</i>, recordándonos que ambos mundos están conectados. Con <i>Malena es un nombre de tango</i>, la autora ahondó en la familia como cauce de los secretos. Pero su consagración definitiva, la apertura de la senda que ha convertido su voz en algo tan necesario, llega a partir de la monumental <i>El corazón helado</i>. </p>
      <p>Grandes abre su presente a la memoria con el repaso histórico de la España del siglo XX a través de historias cercanas, cotidianas, forjadas con la corporeidad de una colorida galería de personajes. De esta manera y gracias a su concienzuda investigación histórica y su compromiso con el bando derrotado, construye sus <i>Episodios de una guerra interminable</i>, a la manera galdosiana, compuestos por cinco novelas en las que relata la resistencia antifranquista tras la Guerra Civil: <i>Inés y la alegría</i> (2010), <i>El lector de Julio Verne</i> (2012), <i>Las tres bodas de Manolita</i> (2014), <i>Los pacientes del doctor García</i> (2017), <i>La madre de Frankenstein</i> (2020) y una más que permanece inédita, pero no por mucho tiempo. </p>
      <p>Almudena falleció de manera temprana, a los 61 años, cuando moría también el año 21, mientras se desvanecía una pandemia pero se fraguaba otra guerra. Interminable, como todo conflicto, como lo es la literatura. </p>
    </div>

    <autopromo></autopromo>
  </section>
</template>

<script>
import Autopromo from "../components/autopromo";
export default {
  name: 'almudena',
  components: {
    Autopromo
  },
}
</script>
