<template>
  <section class="section">
    <div id="section1" style="position: absolute; top: -57px; left: 0"></div>
    <div class="section-wrapper">
      <div class="section-header">
        <span class="section-header-number"></span>
        <span class="section-header-title">Acentos del español</span>
        <span class="section-header-subtitle">
          <div class="name">Por Arsenio Escolar</div>
          <div class="info">Editor y director de Archiletras</div>
        </span>
      </div>

      <figure class="section-image">
        <img class="margin" src="../assets/img/acentos.png">
      </figure>
      <p>La lengua es lo más humano de los seres humanos. Lo que nos hace humanos. Lo que ha creado la civilización. La lengua, cada lengua, es además el principal patrimonio colectivo de sus hablantes. La nuestra, el español, es una de las más extendidas del mundo. Según los últimos datos oficiales del Instituto Cervantes, la hablamos casi 600 millones de personas, el 7,5% de la población del planeta. De ellas, casi 500 millones la tenemos como lengua materna, y otros 100 millones como lengua aprendida o camino de hacerlo. Todos, los primeros y los segundos, utilizamos el mismo idioma, pero lo hacemos de modo diverso. Nos entendemos perfectamente entre todos nosotros, pero nos expresamos de manera diferente, en las distintas variedades dialectales del español. Los expertos dicen que nuestro idioma tiene ocho variedades diferentes: la castellana, la andaluza, la canaria, la caribeña, la mexicano-centroamericana, la andina, la chilena y la rioplatense.</p>
      <p>Cada una de las ocho variedades dialectales del español tiene entre sí tres tipos de diferencias o de peculiaridades: las fonéticas o de pronunciación, las gramaticales y las de vocabulario. Aquí nos vamos a fijar hoy solamente en la primera de ellas.</p>
      <p>Seseo, ceceo, lleísmo, yeísmo, heheo o jejeo, rehilamiento, relajación de /x/, relajación de /s/ en final de sílaba, velarización de /n/... Estos son algunos de los fenómenos fonéticos que se observan al escuchar el fragmento seleccionado de Almudena Grandes, en las distintas voces, con el peculiar acento, de los escritores y escritoras que se expresan en las diferentes variedades dialectales del español. ¿Hay alguna variedad más correcta que otras? No. Todas son igual de correctas, lícitas o legítimas. Y todas juntas revelan la unidad y al mismo tiempo la variedad de nuestro idioma.</p>
    </div>

    <autopromo></autopromo>
  </section>
</template>

<script>
import Autopromo from "../components/autopromo";
export default {
  name: 'acentos',
  components: {
    Autopromo
  },
}
</script>
